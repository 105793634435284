import React from 'react';
import logo from './logo.svg';
import './App.css';
import ChatBot from 'react-simple-chatbot';

function App() {
  return (
    <div className="App">
      <ChatBot
  steps={[
    {
      id: '1',
      message: 'What is your name?',
      trigger: '2',
    },
    {
      id: '2',
      user: true,
      trigger: '3',
    },
    {
      id: '3',
      message: 'Hi {previousValue}, nice to meet you!',
      end: true,
    },
  ]}
/>
    </div>
  );
}

export default App;
